import React, { useState } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import RichText from '../components/richText'

import styled from '@emotion/styled'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
// import './Gallery.css'

import { breakpoints } from '~/utils/styles'

const FormatCols = styled.div`
  h2 {
    font-weight: bold;
  }
  @media (min-width: ${breakpoints.l}px) {
    display: flex;
    margin: 0 -4rem;
  }
  > div {
    @media (min-width: ${breakpoints.l}px) {
      padding: 0 4rem;
    }
    &:nth-of-type(1) {
      @media (min-width: ${breakpoints.l}px) {
        width: 40%;
      }
    }
    &:nth-of-type(2) {
      @media (min-width: ${breakpoints.l}px) {
        width: 60%;
      }
    }
  }
`

const Examples = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 6rem -1rem 0;
  li {
    margin-bottom: 2rem;
    width: 50%;
    padding: 0 1rem;
    @media (min-width: ${breakpoints.l}px) {
      width: 33.33%;
    }
  }
  p {
    font-weight: bold;
  }
  img {
    width: 100%;
    border-radius: 2rem;
    display: block;
    margin-top: 3rem;
  }
`

const Sizes = styled.div`
  @media (min-width: ${breakpoints.l}px) {
    display: flex;
    margin: 0 -4rem;
  }
  > div {
    @media (min-width: ${breakpoints.l}px) {
      padding: 0 4rem;
      width: 50%;
    }
  }
  img {
    width: 100%;
  }
`

const FormatTypes = ({ data }) => {
  console.log(data)

  const {
    sizes,
    title,
    illustration,
    format_types: formatTypes,
    examples,
  } = data.allPrismicFormatTypes.nodes[0].data

  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [image, setImage] = useState(false)

  return (
    <>
      <SEO title="Format types" />
      <FormatCols>
        <div>
          <h2>FORMAT TYPES:</h2>
          <RichText render={formatTypes.raw} />
          <div>
            <Examples>
              {examples.map((example, i) => {
                return (
                  <li key={i}>
                    <div
                      onClick={() => {
                        setIsOpen(true)
                        // setIndex(thumbIndex)
                        setImage(example.image.url)
                      }}
                    >
                      <p>{example.label}</p>
                      <img src={example.image.url} />
                    </div>
                  </li>
                )
              })}
            </Examples>
          </div>
        </div>
        <Sizes>
          <div>
            <h2>Sizes</h2>
            <RichText render={sizes.raw} />
          </div>
          <div>
            <div
              onClick={() => {
                setIsOpen(true)
                // setIndex(thumbIndex)
                setImage(illustration.url)
              }}
            >
              <img src={illustration.url} />
            </div>
          </div>
        </Sizes>
      </FormatCols>
      {isOpen && (
        <Lightbox
          mainSrc={image}
          // mainSrc={fullSize[index]}
          // nextSrc={fullSize[(index + 1) % fullSize.length]}
          // prevSrc={
          //   fullSize[(index + fullSize.length - 1) % fullSize.length]
          // }
          onCloseRequest={() => setIsOpen(false)}
          // onMovePrevRequest={() =>
          //   setIndex((index + fullSize.length - 1) % fullSize.length)
          // }
          // onMoveNextRequest={() =>
          //   setIndex((index + 1) % fullSize.length)
          // }
        />
      )}
    </>
  )
}

export const query = graphql`
  {
    allPrismicFormatTypes {
      nodes {
        data {
          sizes {
            raw
          }
          title {
            raw
          }
          illustration {
            url
          }
          format_types {
            raw
          }
          examples {
            label
            image {
              url
            }
          }
        }
      }
    }
  }
`

export default FormatTypes
